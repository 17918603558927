// Height values in vh

.h-100vh {
	height: 100vh !important;
}

@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

        @each $prop, $abbrev in (top: top) {
            @each $size, $length in $sizes {

                // Spacing (for elements with absolute position)
                .mw#{$infix}-#{$size} {
                    max-width: $length !important;
                }


            }
        }
    }
}
