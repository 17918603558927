//
// Badge
//


.badge {
    display: inline;
    text-transform: $badge-text-transfom;

    i:not(:last-child),
    svg {
        margin-right: .5rem;
    }
}

.badge-group {
    .badge {
        display: inline-block;
        margin-top: .125rem;
        margin-bottom: .125rem;
        margin-right: .25rem;
    }
}
// Size variations

.badge-xs {
    padding: .25rem .5rem;
    font-size: 60%;
}

.badge-md {
    padding: .65em 1em;
}

.badge-lg {
    padding: .85em 1.375em;
}


// Multiple inline badges

.badge-inline {
    margin-right: .625rem;

    + span {
        top: 2px;
        position: relative;

        > a {
            text-decoration: underline;
        }
    }
}


// Badge spacing inside a btn with some text

.btn {
    .badge {
        &:not(:first-child) {
            margin-left: .5rem;
        }
        &:not(:last-child) {
            margin-right: .5rem;
        }
    }
}

// Soft color variation

@each $color, $value in $theme-colors {
    .badge-soft-#{$color} {
        @include badge-soft-variant(theme-color-level($color, $badge-soft-bg-level), $value);
    }
}
